<template>
  <div class="orderHistory">
    <mt-header :title="$t('orderHistory')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <ul>
        <li v-for="(item, index) in list" :key="index">
          <div class="wrap">
            <div class="title">
              <mt-cell :title="$t('contractNo')" is-link :to="`/apply/loanDetail?contractNo=${item.contractNo}`">
                <div>{{ item.contractNo }}</div>
                <img slot="icon" src="../../assets/detail.png">
              </mt-cell>
            </div>
            <div class="row border-b">
              <div class="name">{{ $t('amount') }}</div>
              <div class="val">{{ item.totalAmount | formatMoney }}</div>
            </div>
            <div class="row border-b">
              <div class="name">{{ $t('applicationDate') }}</div>
              <div class="val">{{ item.applyDate | formatDate('DD-MM-YYYY') }}</div>
            </div>
            <div class="row">
              <div class="name">{{ $t('contractStatus') }}</div>
              <div class="val">{{ item.statusName }}</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import callPhone from '@/components/callPhone.vue'
import { contractList } from '../../utils/api'
export default {
  name: 'OrderHistory',
  components: {
    callPhone
  },
  data() {
    return {
      list: [],
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await this.loadMore()
      this.$indicator.close();
    },
    async loadMore() {
      await this.$axios({
        method: 'get',
        url: contractList,
      })
        .then((e) => {
          if (e.status.code == '000') {
            this.list = e.body
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.orderHistory {
  ul,li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  ul {
    flex-grow: 1;
    position: relative;
    padding: 10px;
    li {
      display: block;
      padding: 10px 15px 0 15px;
      text-decoration: none;
      border: 1px solid #eaeaea;
      border-radius: 10px;
      margin: 10px 0px;
    }
    .wrap {
      background: #fff;
      border-radius: 10px;
      .name {
        color: #757575;
        font-size: 12px;
      }
      .val {
        color: #000;
        font-size: 14px;
        color: #383838;
      }
    }
    .title {
      img {
        width: 16px;
        margin: 0 5px;
      }
    }
    .row {
      display: flex;
      height: 50px;
      align-items: center;
      justify-content: space-between;
      margin-left: 20px;
      padding-right: 20px;
    }
  }
}
</style>